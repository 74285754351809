import { Fragment } from 'react';
import {
  Container,
  Stack,
  useColorModeValue,
  VStack,
  Flex,
  Text,
  Icon,
  Divider
} from '@chakra-ui/react';
// Here we have used react-icons package for the icons
import { GoLocation } from 'react-icons/go';
import { BsPhone } from 'react-icons/bs';
import { HiOutlineMail } from 'react-icons/hi';
import { MotionBox } from "../motion";
import 'react-phone-number-input/style.css';
import Header from "../header";
import {SlickSliderContact} from '../slider/SlickSlider';


const contactOptions = [
  {
    label: 'Address',
    value: 'Sahiwal, Punjab, PK',
    icon: GoLocation,
    wantToLink: false
  },
  {
    label: 'PHONE NUMBER',
    value: '+92 317 8281536',
    icon: BsPhone,
    wantToLink: true,
  },
  {
    label: 'EMAIL',
    value: 'hi@nomanmujahid.com',
    icon: HiOutlineMail,
    wantToLink: true
  }
];

const Calendly = () => {
  return (
    <>
    <Container maxW="7xl" py={10} px={{ base: 5, md: 8 }}>
      <Stack spacing={10}>
        <Flex align="center" justifyContent="center" direction="column">
          <MotionBox>
              <VStack>
                <Header mt={0} mb={1}>
                  Get In Touch
                </Header>
                <Text
                  fontSize={"xl"}
                  color={useColorModeValue("gray.500", "gray.200")}
                  maxW="lg"
                  textAlign="center"
                >
                Ready to elevate your project with a fusion of expertise and innovation! 🚀🔧
                </Text>
              </VStack>
                {/* <Stack pl={3}>
                    <Box as={BsFillBriefcaseFill} size="25px" />
                </Stack> */}
            </MotionBox>
        </Flex>
        <SlickSliderContact>
          {contactOptions.map((option, index) => (
            <Fragment key={index}>
              <Stack
                spacing={3}
                direction="column"
                justifyContent="center"
                alignItems="center"
                px={3}
              >
                <Icon as={option.icon} w={10} h={10} color="#53c8c4" />
                <Text fontSize="lg" fontWeight="semibold">
                  {option.label}
                </Text>
                {option.wantToLink ? (<>
                  {option.label === 'PHONE NUMBER' ? 
                    <Text fontSize="md" textAlign="center">
                      <a href={`tel:${option.value}`} target="_blank" rel="noopener noreferrer">
                        {option.value}
                      </a>
                    </Text>
                    : <>
                      {option.label === 'EMAIL' ?  
                        <Text fontSize="md" textAlign="center">
                          <a href={`mailto:${option.value}`} target="_blank" rel="noopener noreferrer">
                            {option.value}
                          </a>
                        </Text>
                        
                      : null}
                    </>}
                  </>)
                  : <>
                    <Text fontSize="md" textAlign="center">
                      {option.value}
                    </Text>
                  </>}
              </Stack>
              {contactOptions.length - 1 !== index && (
                <Flex d={{ base: 'none', md: 'flex' }}>
                  <Divider orientation="vertical" />
                </Flex>
              )}
            </Fragment>
          ))}
        </SlickSliderContact>
      </Stack>
    </Container>
      <div className="calendly-inline-widget" data-url="https://calendly.com/hi--vadw/free-consultation?primary_color=53c8c4" style={{ minWidth: '320px', height: '700px' }}>
          
      </div>
    </>
  )
}
export default Calendly;