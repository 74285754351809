import { VStack, Text, useColorModeValue } from "@chakra-ui/react";
import Section from "./section";
import Header from "./header";

export const HeaderSection = ({heading, description}) => {
    return (
        <Section>
            <VStack>
            <Header mt={0} mb={1}>
                {heading}
            </Header>
            <Text
                fontSize={"xl"}
                color={useColorModeValue("gray.500", "gray.200")}
                maxW="lg"
                textAlign="center"
            >
                {description}
            </Text>
            </VStack>
        </Section>
    )
} 