import botBenchmark from "../assets/images/projects/bot-benchmark.png";
import budCars from "../assets/images/projects/bud-cars.png";
import deliverEz from "../assets/images/projects/deliverez.png";
import softCity from "../assets/images/projects/soft-city.png";
// import studyAbroad from "../assets/images/projects/study-abroad.png";
import vipInfluencer from "../assets/images/projects/vip-influencers.png";

export const projectsList = [
  {
    id: 1,
    title: "BudCars Cannabis Delivery - (General Purpose E-Commerce Website)",
    projectImage: budCars,
    link: "https://cannabis.website.codebreakers.online/",
    desc: `Welcome to our advanced E-commerce platform, powered by the MERN stack. It offers comprehensive shopping across categories, empowering admins, and ensuring efficiency. Mobile apps, real-time tracking, and a modern design enhance user experience. Join us for a seamless shopping journey!`,
    technologies: ["Full Stack Development", "MERN", "React.js", "Node.js", "Express.js", "MongoDB"]
  },
  {
    id: 2,
    title: "DeliverEz - (Logistics Website)",
    projectImage: deliverEz,
    link: "https://deliverez.pk/",
    desc: `DeliverEz is revolutionizing Pakistan's e-commerce and logistics sectors with its tech-driven platform. It addresses challenges for both businesses and consumers by streamlining processes, enabling seamless expansion and delivery of goods.`,
    technologies: ["Full Stack Development", "HTML/CSS/BootStrap", "Ejs", "Node.js", "Express.js", "MongoDB"]
  },
  {
    id: 3,
    title: "Vip Influencers - (Recruiting Freelancers for Leading Brands)",
    projectImage: vipInfluencer,
    link: "https://vipinfluencers.com/",
    desc: `I developed a platform where influencers and brands can connect seamlessly. Influencers set their rates, and brands can hire them directly through the site. Integrated with Stripe for secure transactions, influencers can create connected accounts to receive payments directly. Chat functionality enables communication between brands and influencers.`,
    technologies: ["Full Stack Development", "MERN", "React.js", "Node.js", "Express.js", "MongoDB", "AWS",  "Linux"]
  },
  {
    id: 4,
    title: "Softcity Data Analytics - (Corporate Software Website)",
    projectImage: softCity,
    link: "#",
    desc: `SoftCity streamlines operations for companies, offering effortless onboarding and intuitive navigation. With comprehensive reporting, administrators gain insights for informed decisions. It's built with front-end mastery and robust back-end solutions, enabling efficient data management and seamless integration with external systems. SoftCity empowers companies to collect and visualize user data on graphs, optimizing performance and productivity.`,
    technologies: ["Full Stack Development", "MERN", "React.js", "Node.js", "Express.js", "MongoDB", "AWS",  "Linux"]
  },
  {
    id: 5,
    title: "Bot Benchmark - (Software Review Website)",
    projectImage: botBenchmark,
    link: "https://botbenchmark.com/",
    desc: `Bot Benchmark is a state-of-the-art software review platform crafted using the MERN stack. It showcases my expertise in dynamic web app development. Highlights include selective software reviews for precise insights, MERN stack's scalability, user-centric design for easy navigation, and trusted, unbiased ratings.`,
    technologies: ["Full Stack Development", "MERN", "React.js", "Node.js", "Express.js", "MongoDB"]
  },
];
