export const ACTION_TYPES = {
    //Users
    GET_UNAPPROVED_USERS: "GET_UNAPPROVED_USERS",
    GET_DEACTIVATE_USERS: "GET_DEACTIVATE_USERS",
    UPDATE_USER_STATUS: "UPDATE_USER_STATUS",
    GET_SINGLE_USER: "GET_SINGLE_USER",

    //progress
    PROGRESS_BAR_STATE: "PROGRESS_BAR_STATE",

    //login
    LOGIN: "LOGIN"
}