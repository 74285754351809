import * as React from "react";
import {
  HStack,
  VStack,
  Text,
  useColorModeValue,
  Link,
  Heading,
  Image,
  Stack,
  Badge,
  Divider
} from "@chakra-ui/react";
import { Box} from '@chakra-ui/react'
import { motion, AnimatePresence } from "framer-motion";
import { GoLocation } from "react-icons/go";

interface ProjectCardProps {
  title: string;
  description: string;
  logo: string;
  blurHash: string;
  link: string;
  technologies: string[];
}

const ProjectCard: React.FC<ProjectCardProps> = ({
  title,
  description,
  logo,
  isLocation,
  link,
  technologies,
  period,
  colorMode,
  compnayLocation,
  imageWidth,
  imageHeight
}) => {
  const textColor = useColorModeValue("gray.500", "gray.200");
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleOpen = () => setIsOpen(!isOpen);

  return (
    <motion.div layout onClick={toggleOpen}>
      <HStack
        p={4}
        display={{base : 'block', md: "flex"}}
        bg={useColorModeValue("white", "gray.800")}
        rounded="xl"
        borderWidth="1px"
        borderColor={useColorModeValue("gray.100", "gray.700")}
        w="100%"
        h="100%"
        // textAlign={{base : "-webkit-center", md: 'left'}}
        textAlign={{base : "left", md: 'left'}}
        align="start"
        spacing={4}
        cursor="pointer"
        _hover={{ shadow: "lg" }}
      >
          <Image
            src={logo}
            alt='Green double couch with wooden legs'
            borderRadius='lg'
            size="sm"
            width={imageWidth}
            height={imageHeight}
          />
        <VStack align="start" w='full' marginStart={{base: '0px !important', pd: '3rem'}} py={{base: '20px', md: '0px'}} justify="flex-start">
          <VStack spacing={0} align="start" w='full'>
            <HStack justify={period !== '' ? "space-between" : ""} w="full">
              <Link to={link} target="__blank">
                <Text
                  as={Link}
                  href={link}
                  fontWeight="bold"
                  fontSize="md"
                  noOfLines={1}
                  onClick={e => e.stopPropagation()}
                  isExternal
                  >
                  {title}
                </Text>
              </Link>
              {period !== '' ? 
                <Stack display={["none", "none", "flex", "flex"]}>
                  <Text fontSize={14} color={`mode.${colorMode}.career.subtext`}>
                    {period}
                  </Text>
                </Stack>
              :null}
            </HStack>
            <AnimatePresence>
              <motion.div
                layout
                initial={{ opacity: 1 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 1 }}
              >
                {!isOpen && (
                  <Text fontSize="sm" color={textColor} noOfLines={{ base: 5 }}>
                    {description}
                  </Text>
                )}
              </motion.div>
            </AnimatePresence>
            <Divider my={4} />
            {isLocation ? 
              <Stack isInline>
                <Box as={GoLocation} size="15px" ></Box>
                <Heading
                  align="left"
                  fontSize="sm"
                  color={`mode.${colorMode}.career.subtext`}
                >
                  {compnayLocation}
                </Heading>
              </Stack>
            : null}
            <HStack spacing="1" display={{base : 'flex'}} pt={3} flexWrap='wrap' gap={3}>
              {technologies.map(tech => (
                <Badge ml="1" colorScheme="green">
                    {tech}
                </Badge>
              ))}
            </HStack>
            <AnimatePresence>
              <motion.div
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                variants={{ exit: { transition: { staggerChildren: 0.1 } } }}
              >
                {isOpen && (
                  <Text
                    fontSize="sm"
                    color={textColor}
                    // noOfLines={{ base: isOpen ? 5 : 2 }}
                  >
                    {description}
                  </Text>
                )}
              </motion.div>
            </AnimatePresence>
          </VStack>
        </VStack>
      </HStack>
    </motion.div>
  //   <Card maxW='sm'>
  //   <CardBody>
  //     <Image
  //       src='https://images.unsplash.com/photo-1555041469-a586c61ea9bc?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1770&q=80'
  //       alt='Green double couch with wooden legs'
  //       borderRadius='lg'
  //     />
  //     <Stack mt='6' spacing='3'>
  //       <Heading size='md'>Living room Sofa</Heading>
  //       <Text>
  //         This sofa is perfect for modern tropical spaces, baroque inspired
  //         spaces, earthy toned spaces and for people who love a chic design with a
  //         sprinkle of vintage design.
  //       </Text>
  //       <Text color='blue.600' fontSize='2xl'>
  //         $450
  //       </Text>
  //     </Stack>
  //   </CardBody>
  //   <Divider />
  //   <CardFooter>
  //     <HStack spacing="1">
  //       {technologies.map(tech => (
  //         <Tag size="sm" colorScheme={getTagColor(tech)}>
  //           {tech}
  //         </Tag>
  //       ))}
  //     </HStack>
  //   </CardFooter>
  // </Card>
  );
};

export default ProjectCard;
