import * as React from "react";
import {
  PageSlideFade,
  StaggerChildren
} from "./page-transitions";
import Testimonial  from './testimonials';
import { HeaderSection } from "./header-section";

const About = () => {

  return (
    <PageSlideFade>
      <StaggerChildren>
        <HeaderSection
            heading="Testimonials"
            description="Elevate your project with expertise and innovation! 🚀🔧 Explore what our clients have to say."
        />
        <Testimonial />
      </StaggerChildren>
    </PageSlideFade>
  );
};

export default About;
