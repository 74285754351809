import * as React from "react";
import {
  VStack,
  useColorMode
} from "@chakra-ui/react";
import ProjectCard from "./project-card";
import {
  PageSlideFade,
  StaggerChildren
} from "./page-transitions";
import { MotionBox } from "./motion";
import { companies, institutes } from "data/data";
import { HeaderSection } from "./header-section";



const About = () => {
  const { colorMode } = useColorMode();

  return (
    <PageSlideFade>
      <StaggerChildren>
      <HeaderSection
        heading="My Career"
        description="Embark on my professional journey, marked by milestones and achievements, as I strive for excellence in every endeavor. 🚀"
      />
        <VStack spacing={4} marginBottom={6} align="left" mx={[0, 0, 6]} mt={12}>
          {companies.map((company, index) => (
            <MotionBox whileHover={{ y: -5 }} key={index}>
              <ProjectCard
                key={index}
                isLocation={true}
                title={company.title}
                description={company.role}
                compnayLocation={company.compnayLocation}
                technologies={company.skills}
                period={company.period}
                logo={company.logo}
                colorMode={colorMode}
                imageWidth={20}
                imageHeight={20}
              />
            </MotionBox>
          ))}
        </VStack>
        <HeaderSection
          heading="My Education"
          description="Trace my educational path, where curiosity meets knowledge, shaping my skills and expertise along the way. 📚"
        />
        <VStack spacing={4} marginBottom={6} align="left" mx={[0, 0, 6]} mt={12}>
          {institutes.map((institute, index) => (
            <MotionBox whileHover={{ y: -5 }} key={index}>
              <ProjectCard
                key={index}
                title={institute.title}
                description={institute.role}
                technologies={institute.skills}
                period={institute.period}
                logo={institute.logo}
                colorMode={colorMode}
                imageWidth={20}
                imageHeight={20}
              />
            </MotionBox>
          ))}
        </VStack>
      </StaggerChildren>
    </PageSlideFade>
  );
};

export default About;
